<template>
<div class='page'> 
 
    <div class="banner">
            <div class="w">
                <div class="info">
                    <div class="title">MEDICAL</div>
                    <p class="title1">The medical industry needs high quality, dependable and safe parts and products so they can deliver healthier and happier lives to all.</p>
                    
                  
                </div>
            </div>
        </div>
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
 <industrieshead :industriesheadLists="industriesheadLists"></industrieshead>
   <applications2 :ApplicationsLists="ApplicationsLists"></applications2>
    <capabilities :CapabilitiesList="CapabilitiesList"></capabilities>
  <customers></customers>
 
</div>
</template>
<script>
import myhead from "../../components/myHead.vue";
import bread from '../../components/bread.vue'
import industrieshead from "../../components/industrieshead.vue";
import applications2 from "../../components/Applications2.vue";
import customers from "../../components/Customers.vue"
import capabilities from "../../components/Capabilities.vue"
export default {
    components: {
    myhead,
    industrieshead,
    applications2,
    customers,
    bread,
    capabilities
  },
  data() {
    return {
            hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
       CapabilitiesList:{
        H1:'MEDICAL POST-PROCESSING CAPABILITIES',
        lists:[{ 
            title:'Anodizing',
        },{
            title:'Passivization',
        },{
            title:'Electroplating',
        },{
            title:'Powder Coating',
        },{
            title:'Insert Installation',
        },{
            title:'Heat Treatment',
        },],
        img: require('../../assets/img/medicalcal1.png')
    }, 
      industriesheadLists: {
        ref: "The medical industry",
        H1: "THE MEDICAL INDUSTRY",
        info: `The world's leading medical device development companies turn to HLH to unlock the benefits of the digital manufacturing model. From connected devices to mass personalization of healthcare products, digital manufacturing accelerates development and market introduction through rapid prototyping, bridge tooling, and low-volume production.`,
        imgs: [
          {
            src: require("../../assets/img/medicalv1.png")
          }
        ],
        h2: "Top Medical Device & Hardware Applications",
        data: [
          {
            content: "Highly variable, low-volume production runs with high-precision tolerances "
          },
          {
            content:   " Custom tooling brackets and chassis for medical manufacturing "
          },
          {
            content:  " Rapid prototyping of medical devices using CNC machining "
          },
          {
            content: "Personal protective equipment (PPE)"
          },
          {
            content: "Ventilator parts"
          }
        ]
      },
      ApplicationsLists: {
        H1: "WHY HLH FOR DEVELOPMENT OF MEDICAL DEVICES？",
        data: [
          {
            img: require("../../assets/img/medicalmode1.png"),
            title: "Bridge Tooling Before Production",
            info:  "Leverage affordable bridge tooling for design and market validation before capital investment in tools."
          },
          {
            img: require("../../assets/img/medicalmode2.png"),
            title: "Medical Materials",
            info:  "Leverage high-speed 3-axis, 4-axis and 5-axis milling processes as well as turning with live tooling for increasingly complex metal and plastic components."
          },
          {
            img: require("../../assets/img/aautomotive1model1.png"),
            title: "Rapid Prototyping",
            info: "Create prototypes in production-grade materials for functional and regulatory testing to preview before medical procedures."
          }
        ]
      }
    };
  },
  
  methods: {},
  mounted() {},
  created() {},
  props: {},
  watch: {},

};
</script>
<style scoped lang="scss">
.page {
}

  .banner{
        background: url('~@/assets/img/medicalbanner.png') no-repeat;
        
  
        width: 100%;
        // height: 560px;
        transition: 0.25s;
    
        padding-top: 70px;
        .w{
            display: flex;
            justify-content: flex-end;
            margin-top: 87px;
        }
    
        
        .title1{
            // font-size: 60px;
            margin-bottom: 2vw;
            transition: 0.25s;
            color: #333333;
          
      
        }
      
        .info {
      
    
            font-size: 24px;
            color: #333333;
            // margin-top: 30px;    
            .item{
                display: flex;
                align-items: center;
            
            }
            >p {
                // margin-bottom: 30px;
                img {
                    vertical-align: middle;
                    margin-right: 20px;
                    // width: 36px;
                    // height: 36px;
                    max-width: 36px;
                    min-width: 25px;
                 
                }
                span {
                    vertical-align: middle;
                }
            }
            >.title {
                // font-size: 48px;
                // margin-bottom: 0;
                color: #333333;
                font-weight: bold;
           transition: 0.25s;
            }
        }
    }
    
@media screen and (max-width: 760px) {   //最小  min-width="330px"
    .info{
        position: absolute;
    right: 2vw;
    top: 38vw;
    }
    .w{
        width: 90%;
        min-width: 330px;
    }
    .title{
        font-size: 23px;
        font-weight: bold;
          margin-bottom: 6vw;
    } 
    .title1{
           font-size: 17px;
        // font-weight: bold;
        width: 62vw;
          // line-height: 25px;
    }
    img{
        width: 26px
    }
    .itemT{
        font-size: 14px;
    }
    p{
        margin-bottom:10px ;
    }
    .banner{
        height: 500px;
          background-position: 37% 100% !important;
    }
     .btn{
        font-size: 14px;
        margin-left: 48px;
    }
}

//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {

    .w{
        width: 90%;
    }
    .title{
        font-size: 35px;
        margin-bottom: 54px;
    }
    .title1{
            font-size: 22px;
              line-height: 36px;
          width:50vw;
    }
    img{
        width: 30px;
    }
    .itemT{
        font-size: 22px;
    }
    p{
        margin-bottom:30px ;
    }
    .banner{
        height: 620px;
        background-position: center !important;
    }
    .btn{
        margin-left: 64px;
        font-size: 16px;
    }
    .info{
        margin-top: 30px;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .w{
        width: 90%;
    }
    .title{
        font-size: 40px;
        margin-bottom: 54px;
    }
   .title1{
        font-size: 22px;
        width:45vw;
          line-height: 36px;
    }
    img{
        width: 36px;}
        .itemT{
            font-size: 24px;
        }  p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}



@media screen and (min-width: 1350px) {  //最大限制   width="1280px"
    .w{
        width: 90%;
    }
    .title{
        font-size: 50px;
        margin-bottom: 54px;
    }
    .title1{
           font-size: 24px;
        width: 650px;
          line-height: 36px;
    }
    .itemT{
        font-size: 24px;
    }
    img{
        width: 36px;}
        p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}
</style>